<template>
    <video :id="`mingle_video`" :poster="returnMedia(thumbnail)"
           class="video-js"
           playsinline controls>
        <!--<source :src="media.Stream[0].org_url" >-->
    </video>
</template>

<script>
import EventBus from "@/utils/event-bus";
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "VideoComponent",
    mixins: [imageOption, imageResizeMixins],
    components: {
    },
    inject: ['videoVueSetData'],
    provide() {
        return{

        }
    },
    props: {
        url: {default: () => {}},
        type: {default: 'application/x-mpegURL'},
        width: {default: 0},
        height: {default: 0},
        thumbnail: {default: "",}
    },
    data() {
        return{
            player: null,
        }
    },
    beforeRouterEnter() {},
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.setVideo();
        })
    },
    beforeDestroy() {
        this.initPlayer()
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setVideo(){
            let $this = this
            let count = this.count;
            let video_ = document.getElementById(`mingle_video`)
            this.player = videojs(`mingle_video`,
                {
                    fullscreen: {
                        navigationUI: "hide"
                    },
                    html5: {
                        vhs: {
                            withCredentials: false,
                            // overrideNative: true
                        }
                    },
                    controlBar: {
                        pictureInPictureToggle: false,
                    },
                    controls: true,
                    width: this.width,
                    height: this.height,
                },
                function onPlayerReady() {
                    // $this.videoVueSetData(`player`, $this.player)
                })


            this.player.ready(function() {
                console.log(' player.ready player.ready player.ready player.ready player.ready!!!!!!!!!!!!!!!!!!!!!!')
                this.src({
                    src: $this.url,
                    type:$this.type
                });
            });



        },
        returnMedia(image) {
            if (util.isEmpty(image)) {
                return '';
            }
            let url = image
            url = url.replace(/%/g,'%25')
            let ex = this.returnExtension(url);
            return `${url}?${this.setImageOptions(this.width, null, ex)}`;
            // return `${url}?${this.setFitWidthImg(url, this.width)}`
        },
        initPlayer() {
            this.player.dispose();
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
