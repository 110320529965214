<template>
    <modal name="play-audio-modal" class="play-audio-modal play-video-modal"
           :width="'900'"
           :height="'680'"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
    >
        <div class="pa10 pt5  bc_white">
            <div class="modal_title mb10">
                <span>{{$t('music')}}</span>
                <button class="cancel_btn" style="z-index: 99" @click="backEvent"></button>
            </div>
<!--            <iframe :src="returnUrl()"
                    frameborder="0"
                    allowfullscreen
                    playsinline
                    allow="autoplay; gyroscope; accelerometer; encrypted-media"
                    style="width: 100%;"
                    height="600"
                    v-if="type === 2"></iframe>-->
<!--            <video :src="src" controls controlslist="nodownload"  v-else></video>-->
            <audio-component :url="src" :type="videoType" :width="880" :height="600" :thumbnail="thumbnail" style="display: block;width: 100%"></audio-component>
        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";
import AudioComponent from "@/components/player/AudioComponent";

export default {
    name: "PlayAudioModal",
    mixins: [],
    components: {
        AudioComponent
    },
    props: {},
    inject: ['changeLoading'],
    provide(){
        return {

        }
    },
    data() {
        return {
            src: '',
            name: '',
            type:1,
            youtubeId:'',
            videoType:'application/x-mpegURL',
            thumbnail: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        backEvent() {
            this.$modal.hide('play-audio-modal');
        },
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.src)) {
                event.stop();
            }
            this.type = 1;
            this.src = event.params.src;
            this.name = event.params.name;
            this.thumbnail = event.params.thumbnail;
            this.videoType = this.returnVideoType(this.src)
        },
        handlerBeforeClose() {
            this.type = '';
        },
        returnVideoType(src){
            let typeIndex = src.lastIndexOf('.')
            let type = src.substring(typeIndex+1)
            if(type === 'm3u8'){
                return 'application/x-mpegURL'
            }
            return 'audio/'+type;
        },
        setData(key,value){
            this[key] = value
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
