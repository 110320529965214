<template>
    <modal name="play-video-modal" class="play-video-modal"
           :width="'900'"
           :height="'700'"
           :scrollable="true"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
    >
        <div class="pa10 pt5  bc_white">
            <div class="modal_title mb20">
                <span>{{ $t('video') }}</span>
                <button class="cancel_btn" style="z-index: 99" @click="backEvent"></button>
            </div>
            <video-component :url="src" :type="videoType" :width="880" :height="600" :thumbnail="thumbnail" style="display: block;width: 100%"></video-component>
        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";
import fetch from "node-fetch";
import VideoComponent from "@/components/player/VideoComponent";

export default {
    name: "PlayVideoModal",
    mixins: [],
    components: {VideoComponent},
    props: {},
    inject: ['changeLoading'],
    provide(){
        return {
            videoVueSetData : this.setData
        }
    },
    data() {
        return {
            src: '',
            name: '',
            thumbnail: '',
            type:1,
            youtubeId:'',
            is360: false,
            videoType:'application/x-mpegURL',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        backEvent() {
            this.$modal.hide('play-video-modal');
        },
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.src)) {
                event.stop();
            }

            this.src = event.params.src;
            this.name = event.params.name;
            this.is360 = event.params.is360;
            this.thumbnail = event.params.thumbnail;
            this.videoType = this.returnVideoType(this.src)

        },
        handlerBeforeClose() {
            this.src = "";
            this.thumbnail = "";
        },
        returnVideoType(src){
            let typeIndex = src.lastIndexOf('.')
            let type = src.substring(typeIndex+1)
            if(type === 'm3u8'){
                return 'application/x-mpegURL'
            }
            return 'video/mp4'
        },
        setData(key,value){
            this[key] = value
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
