<template>
    <div class="info_detail">
        <h3>{{ $t('i_block') }}</h3>
        <ul v-if="checkBlock()">
            <li v-for="(block,index) in blockDataKey" :key="`block${index}`" v-if="checkData(block)">
                <h3>{{ returnType(block) }}</h3>
                <span>{{ blockData[block] }}</span>
            </li>
        </ul>
        <div v-else>
            {{ $t('block_data_error') }}
        </div>
    </div>

</template>

<script>
import util from "@/mixins/util";

export default {
    name: "BlockChainComponent",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        blockData: '',
    },
    data() {
        return {
            blockDataKey: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (!util.isEmpty(this.blockData)) {
            this.blockDataKey = Object.keys(this.blockData)
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        checkData(data) {
            if (data === 'imageUrl' || data === 'nftOwners') {
                return false
            }
            if (util.isEmpty(this.blockData[data])) {
                return false
            }
            return true
        },
        returnType(type) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        },
        checkBlock() {
            return !util.isEmpty(this.blockData)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>